import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  margin-top: 100px;
  margin-bottom: 100px;
  z-index: 400;
  & > div:nth-child(1) {
    margin-bottom: 100px;
  }

  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const PrivacyWrapper = styled.div`
  & > li,
  ul {
    color: ${props => props.theme.colors.darkBlue};
    font-size: ${props => props.theme.fontSizes.small};
  }
`

export const H3 = styled.h3`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSizes.normal};
  color: ${props => props.theme.colors.darkBlue};
`
