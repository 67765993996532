import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import PrivacyPolicyComponent from "../components/Sections/PrivacyPolicy"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Head
        description="Privacy policy"
        pageTitle="Privacy policy"
        keywords="software, oakfusion"
      />
      <PrivacyPolicyComponent />
    </Layout>
  )
}

export default PrivacyPolicy
