import React from "react"
import { Container, H3, PrivacyWrapper } from "./style"
import { H2Title, Section, P, GrayBorder, M } from "../../../globalStyle"

const PrivacyPolicyComponent = () => {
  return (
    <Section>
      <Container>
        <M>
          <H2Title
            style={{ marginLeft: "15px", marginRight: "15px" }}
            center={true}
          >
            Privacy Policy
          </H2Title>
          <GrayBorder />
          <PrivacyWrapper>
            <P>
              This privacy policy has been compiled to better serve those who
              are concerned with how their ‘Personally Identifiable Information’
              (PII) is being used online. PII, as described in US privacy law
              and information security, is information that can be used on its
              own or with other information to identify, contact, or locate a
              single person, or to identify an individual in context. Please
              read our privacy policy carefully to get a clear understanding of
              how we collect, use, protect or otherwise handle your Personally
              Identifiable Information in accordance with our website. What
              personal information do we collect from the people that visit our
              blog, website or app? When ordering or registering on our site, as
              appropriate, you may be asked to enter your name, email address,
              phone number, Location data from cellular and gps or other details
              to help you with your experience. When do we collect information?
              We collect information from you when you register on our site, Use
              Live Chat or enter information on our site. Sharing location
            </P>
            <H3>How do we use your information?</H3>
            <P>
              We may use the information we collect from you when you register,
              make a purchase, sign up for our newsletter, respond to a survey
              or marketing communication, surf the website, or use certain other
              site features in the following ways:
            </P>
            <ul>
              <li>
                To personalize your experience and to allow us to deliver the
                type of content and product offerings in which you are most
                interested.
              </li>
              <li>
                To allow us to better service you in responding to your customer
                service requests.
              </li>
              <li>
                To follow up with them after correspondence (live chat, email or
                phone inquiries)
              </li>
            </ul>

            <H3>How do we protect your information?</H3>
            <P>
              We do not use vulnerability scanning and/or scanning to PCI
              standards. We only provide articles and information. We never ask
              for credit card numbers. We do not use Malware Scanning. Your
              personal information is contained behind secured networks and is
              only accessible by a limited number of persons who have special
              access rights to such systems, and are required to keep the
              information confidential. In addition, all sensitive/credit
              information you supply is encrypted via Secure Socket Layer (SSL)
              technology. We implement a variety of security measures when a
              user enters, submits, or accesses their information to maintain
              the safety of your personal information. All transactions are
              processed through a gateway provider and are not stored or
              processed on our servers.
            </P>
            <H3>Do we use ‘cookies’?</H3>
            <P>
              Yes. Cookies are small files that a site or its service provider
              transfers to your computer’s hard drive through your Web browser
              (if you allow) that enables the site’s or service provider’s
              systems to recognize your browser and capture and remember certain
              information. For instance, we use cookies to help us remember and
              process the items in your shopping cart. They are also used to
              help us understand your preferences based on previous or current
              site activity, which enables us to provide you with improved
              services. We also use cookies to help us compile aggregate data
              about site traffic and site interaction so that we can offer
              better site experiences and tools in the future.
            </P>
            <H3>We use cookies to:</H3>

            <ul>
              <li>Help remember and process the items in the shopping cart.</li>
              <li>Understand and save user’s preferences for future visits.</li>
              <li>Keep track of advertisements.</li>
              <li>
                Compile aggregate data about site traffic and site interactions
                in order to offer better site experiences and tools in the
                future. We may also use trusted third-party services that track
                this information on our behalf.
              </li>
            </ul>

            <P>
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies. You do
              this through your browser settings. Since each browser is a little
              different, look at your browser’s Help Menu to learn the correct
              way to modify your cookies. If users disable cookies in their
              browser: If you turn cookies off, Some of the features that make
              your site experience more efficient may not function properly.Some
              of the features that make your site experience more efficient and
              may not function properly.
            </P>
            <H3>Third-party disclosure</H3>
            <P>
              We do not sell, trade, or otherwise transfer to outside parties
              your Personally Identifiable Information unless we provide users
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our website,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when it’s release is appropriate to comply
              with the law, enforce our site policies, or protect ours or
              others’ rights, property or safety. However, non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses.
            </P>
            <H3>Third-party links</H3>
            <P>
              ccasionally, at our discretion, we may include or offer
              third-party products or services on our website. These third-party
              sites have separate and independent privacy policies. We therefore
              have no responsibility or liability for the content and activities
              of these linked sites. Nonetheless, we seek to protect the
              integrity of our site and welcome any feedback about these sites.
            </P>
            <H3>Google</H3>
            <P>
              Google’s advertising requirements can be summed up by Google’s
              Advertising Principles. They are put in place to provide a
              positive experience for users.
              https://support.google.com/adwordspolicy/answer/1316548?hl=en We
              use Google AdSense Advertising on our website. Google, as a
              third-party vendor, uses cookies to serve ads on our site.
              Google’s use of the DART cookie enables it to serve ads to our
              users based on previous visits to our site and other sites on the
              Internet. Users may opt-out of the use of the DART cookie by
              visiting the Google Ad and Content Network privacy policy.
            </P>
            <P>We have implemented the following:</P>

            <ul>
              <li>Demographics and Interests Reporting</li>
            </ul>

            <P>
              We, along with third-party vendors such as Google use first-party
              cookies (such as the Google Analytics cookies) and third-party
              cookies (such as the DoubleClick cookie) or other third-party
              identifiers together to compile data regarding user interactions
              with ad impressions and other ad service functions as they relate
              to our website.
            </P>
            <H3>Opting out:</H3>
            <P>
              Users can set preferences for how Google advertises to you using
              the Google Ad Settings page. Alternatively, you can opt out by
              visiting the Network Advertising Initiative Opt Out page or by
              using the Google Analytics Opt Out Browser add on.
            </P>
            <P>California Online Privacy Protection Act</P>
            <P>
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require any person or
              company in the United States (and conceivably the world) that
              operates websites collecting Personally Identifiable Information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals or companies with whom it is being shared. – See
              more at:
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
              According to CalOPPA, we agree to the following: Users can visit
              our site anonymously. Once this privacy policy is created, we will
              add a link to it on our home page or as a minimum, on the first
              significant page after entering our website. Our Privacy Policy
              link includes the word ‘Privacy’ and can easily be found on the
              page specified above.
            </P>
            <P>You will be notified of any Privacy Policy changes:</P>

            <ul>
              <li>On our Privacy Policy Page</li>
            </ul>

            <P>Can change your personal information:</P>

            <ul>
              <li>By emailing us</li>
            </ul>

            <P>
              How does our site handle Do Not Track We honor Do Not Track
              signals and Do Not Track, plant cookies, or use advertising when a
              Do Not Track (DNT) browser mechanism is in place.signals?
            </P>
            <P>
              We honor Do Not Track signals and Do Not Track, plant cookies, or
              use advertising when a Do Not Track (DNT) browser mechanism is in
              place.
            </P>
            <H3>Does our site allow third-party behavioral tracking?</H3>
            <P>
              It’s also important to note that we do not allow third-party
              behavioral tracking COPPA (Children Online Privacy Protection Act)
              When it comes to the collection of personal information from
              children under the age of 13 years old, the Children’s Online
              Privacy Protection Act (COPPA) puts parents in control. The
              Federal Trade Commission, United States’ consumer protection
              agency, enforces the COPPA Rule, which spells out what operators
              of websites and online services must do to protect children’s
              privacy and safety online. We do not specifically market to
              children under the age of 13 years old. Do we let third-parties,
              including ad networks or plug-ins collect PII from children under
              13?
            </P>
            <H3>Fair Information Practices</H3>
            <P>
              The Fair Information Practices Principles form the backbone of
              privacy law in the United States and the concepts they include
              have played a significant role in the development of data
              protection laws around the globe. Understanding the Fair
              Information Practice Principles and how they should be implemented
              is critical to comply with the various privacy laws that protect
              personal information. In order to be in line with Fair Information
              Practices we will take the following responsive action, should a
              data breach occur:
            </P>
            <P>We will notify you via email</P>

            <ul>
              <li>Within 7 business days</li>
            </ul>
            <P>
              We also agree to the Individual Redress Principle which requires
              that individuals have the right to legally pursue enforceable
              rights against data collectors and processors who fail to adhere
              to the law. This principle requires not only that individuals have
              enforceable rights against data users, but also that individuals
              have recourse to courts or government agencies to investigate
              and/or prosecute non-compliance by data processors.
            </P>
            <H3>CAN SPAM Act</H3>
            <P>
              The CAN-SPAM Act is a law that sets the rules for commercial
              email, establishes requirements for commercial messages, gives
              recipients the right to have emails stopped from being sent to
              them, and spells out tough penalties for violations. We collect
              your email address in order to:
            </P>

            <ul>
              <li>
                Send information, respond to inquiries, and/or other requests or
                questions
              </li>
            </ul>

            <P>To be in accordance with CANSPAM, we agree to the following:</P>

            <ul>
              <li>Not use false or misleading subjects or email addresses.</li>
              <li>
                Identify the message as an advertisement in some reasonable way.
              </li>
              <li>
                Include the physical address of our business or site
                headquarters.
              </li>
              <li>
                Monitor third-party email marketing services for compliance, if
                one is used.
              </li>
              <li>Honor opt-out/unsubscribe requests quickly.</li>
              <li>
                Allow users to unsubscribe by using the link at the bottom of
                each email.
              </li>
            </ul>

            <P>
              If at any time you would like to unsubscribe from receiving future
              emails, you can email us at
            </P>

            <ul>
              <li>Follow the instructions at the bottom of each email.</li>
            </ul>

            <P>and we will promptly remove you from ALL correspondence.</P>
            <H3>Contacting Us</H3>
            <P>
              If there are any questions regarding this privacy policy, you may
              contact us using the information below.
            </P>
            <address>
              <P>oakfusion</P>
              <P>ul. Żmigrodzka 244</P>
              <P>51-131 Wrocław</P>
              <P>Poland</P>
              <P>Email: office@oakfusion.pl</P>
            </address>
          </PrivacyWrapper>
        </M>
      </Container>
    </Section>
  )
}

export default PrivacyPolicyComponent
